<template>
    <div>
        <v-dialog v-model="secondRequestFormDialog" width="1000px">
            <v-card >
                <v-card class="bordered-card">
                    <v-toolbar dense class="d-flex justify-center" style="font-size:30px;border-bottom: 2px solid black;" flat><span>幹線支給依頼書</span></v-toolbar>
                        <!-- <v-card flat  style="border-bottom: 2px dotted black;margin-top:1%" height="100px">
                            <span style="margin-left:2%;font-size:14px">本物件は、ユニット配線の物件となり、幹線の支給を行っています。</span><br>
                            <span style="margin-left:2%;font-size:12px">参考値欄に記載されている数量、サイズ、長さなどをご確認いただき、発注時欄に実際に必要な数値を記載し、FAXにて返信をお願いいたします。</span><br>
                            <span style="margin-left:2%;font-size:14px">支給する材料は、上棟前後に貴社宛に送付させていただきます。</span><br>
                            <span style="margin-left:2%;font-size:12px">尚、幹線のサイズは、余長を除いた長さで電圧降下の検討を行っています。</span>
                        </v-card> -->
                    <v-row>
                        <!-- Text Fields on the Left -->
                        <v-col cols="6">
                                <v-layout>
                                    <span style="font-size:16px;margin-top:1%;margin-left:3%"> <b>貴社名:</b></span>
                                    <!-- <v-text-field readonly dense v-model="ObjectForTrunkline.subcon318" style="font-size:16px; font-weight: bold;margin-top:1%;margin-left:6%;"></v-text-field> -->
                                    <span style="margin-left:6%;font-size:20px;text-decoration: underline;color:blue">{{ObjectForTrunkline.subcon318}}</span>
                                    <span style="margin-left:6%;margin-top:1%">-</span>
                                    <v-text-field 
                                        readonly 
                                        dense 
                                        v-model="ObjectForTrunkline.subconName" 
                                        style="margin-left:3%;font-size:16px; font-weight: bold;margin-right:3%" >
                                    </v-text-field>
                                </v-layout>

                                <v-layout>
                                    <span style="margin-left:3%;font-size:16px;margin-top:1%"> <b>お客様コード :</b></span>
                                    <v-text-field  
                                        v-model="ObjectForTrunkline.code_number" 
                                        style="margin-left:2%;font-size:16px;margin-right:3%;font-weight:bold;" 
                                        dense 
                                        readonly > 
                                    </v-text-field>
                                </v-layout>   
                                <!-- class="mt-n5" -->
                                <v-layout>
                                    <span style="font-size:16px;margin-top:1%;margin-left:3%"> <b>現場名:</b></span>
                                    <v-text-field 
                                        readonly 
                                        style="margin-left:2%;font-size:16px;margin-right:3%;font-weight:bold" 
                                        dense 
                                        v-model="ObjectForTrunkline.customerName" >
                                    </v-text-field>
                                </v-layout>

                                <v-layout >
                                    <span style="font-size:16px;margin-top:1%;margin-left:2%"> <b>上棟予定日:</b></span>
                                    <v-text-field 
                                        readonly 
                                        style="margin-left:2%;font-size:16px;margin-right:3%;" 
                                        dense 
                                        v-model="ObjectForTrunkline.joutou"> 
                                    </v-text-field>
                                </v-layout>

                                <v-layout>
                                    <span style="font-size:16px;margin-top:1%;margin-left:3%"> <b>展示場 :</b></span>
                                    <v-text-field 
                                        readonly 
                                        style="margin-left:6%;font-size:16px;margin-right:3%" 
                                        v-model="ObjectForTrunkline.exhibitionBranch" 
                                        dense>
                                    </v-text-field>
                                </v-layout> 
                        </v-col>

                        <!-- Image on the Right -->
                        <v-col cols="6">
                            <v-img src="/img/subcon.jpg" max-height="250" style="margin-top:4%;margin-right:20%;margin-left:5%"></v-img>
                        </v-col>
                        </v-row>
                

                    <!-- FIRST TABLE -->
                    <v-card style="margin-left:2%;margin-right:2%;margin-bottom:3%;margin-top:2%" flat>
                        <!-- <span  style="margin-top:2%">* 発注時欄の空欄に数量を必ず記載してください。</span> -->
                        <v-toolbar class="mt-1" flat dense color="blue grey darken-4 white--text" tile>
                            <v-row align="center">
                                <v-col cols="11" class="d-flex justify-space-around">
                                    <v-sheet flat dense color="blue grey darken-4 white--text" tile>
                                        幹線
                                    </v-sheet>
                                </v-col>
                                <v-col cols="1" class="d-flex justify-space-around">
                                    <v-sheet flat dense color="blue grey darken-4 white--text" tile>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-toolbar>
                        <v-row>
                            <v-col v-for="(item ,i) in mainLine" :key="i">
                                <v-simple-table dense>
                                    <thead>
                                        <tr>
                                            <th colspan="2"></th>
                                            <th v-for="(header , h) in Object.keys(item[0])" :key="h" class="text-center">
                                                
                                                {{header.toUpperCase()}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(body , b) in item" :key="b">
                                            <td rowspan="3">参考値</td>
                                            <td >電灯</td>
                                            <td  class="text-center">
                                                <a-input v-model="ObjectForTrunkline.cc_c1"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense></a-input>
                                                <!-- <v-combobox v-model="ObjectForTrunkline.cc_c1" v-else :items="selectionCategory" :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense hide-details style="width:100px"></v-combobox> -->
                                                <template>
                                                    <a-select v-model="ObjectForTrunkline.cc_c1"  @change="UpdateCc1(ObjectForTrunkline.cc_c1)" v-if="showPlannerForm"  style="width:100%;color:rgb(134, 17, 1);font-weight:bold"   >
                                                        <a-select-option
                                                        v-for="item in selectionCategory"
                                                        :key="item.value"
                                                        :value="item.value"
                                                        >
                                                        {{ item.text }}
                                                        </a-select-option>
                                                    </a-select>
                                                </template>
                                            </td> 

                                            <td ><a-input v-model="ObjectForTrunkline.kansen_blankc1"  :readOnly="!showPlannerForm"  :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense></a-input></td>
                                            <td >
                                                <a-input v-model="ObjectForTrunkline.sizeCV_c1" v-if="!showPlannerForm" :readOnly="!showPlannerForm"  :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense></a-input>
                                                <!-- <v-combobox v-model="ObjectForTrunkline.sizeCV_c1" v-else :items="selectionCategory" :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense hide-details style="width:100px"></v-combobox> -->
                                                <template>
                                                    <a-select v-model="ObjectForTrunkline.sizeCV_c1" v-if="showPlannerForm"  style="width:100%;color:rgb(134, 17, 1);font-weight:bold"   >
                                                        <a-select-option
                                                        v-for="item in selectionCvt"
                                                        :key="item.value"
                                                        :value="item.value"
                                                        >
                                                        {{ item.text }}
                                                        </a-select-option>
                                                    </a-select>
                                                </template>
                                            </td>
                                            <td ><a-input v-model="ObjectForTrunkline.quantity_c1" :readOnly="!showPlannerForm"  :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense></a-input></td>
                                        
                                            <td >
                                            
                                                <a-input v-model="ObjectForTrunkline.wa_c1"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="ObjectForTrunkline.wa_c1" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option v-for="num1 in 50" :key="num1" :value="num1">{{ num1 }}</a-select-option>
                                                </a-select>
                                            </td>
                                            <td rowspan="2">
                                                <a-input v-model="ObjectForTrunkline.eb_c1"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense style="text-align:center;height:50px"></a-input>
                                                <a-select v-model="ObjectForTrunkline.eb_c1" v-if="showPlannerForm" style="width:80%" class="fieldclass">
                                                    <a-select-option v-for="number in 10" :key="number" :value="number">{{ number }}</a-select-option>
                                                </a-select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="3%" v-if="ObjectForTrunkline.type != 'Zenryou'">余剰</td>
                                            <td width="3%" v-else>全量</td>
                                            <td width="13%"><v-layout style="border:1px solid rgb(170, 170, 170);border-radius: 5px;">
                                                <a-input v-model="ObjectForTrunkline.cc_c2" :readOnly="!showPlannerForm" :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'"  outlined dense  style="width:80%"></a-input>
                                                                        <span style="margin-top:15%;font-weight:bold" >kW</span>
                                                            </v-layout>
                                            </td>
                                            <td width="13%"><a-input readOnly :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'"   outlined dense></a-input></td>


<!-- //TODO ONGOING -->
                                            <td width="13%" v-if="ObjectForTrunkline.type != 'Zenryou'"><a-input readOnly :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'"   outlined dense></a-input></td>

                                            <td v-else>
                                                <a-input v-model="ObjectForTrunkline.sizeCV_c2" v-if="!showPlannerForm" :readOnly="!showPlannerForm"  :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense></a-input>
                                                <!-- <v-combobox v-model="ObjectForTrunkline.sizeCV_c1" v-else :items="selectionCategory" :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense hide-details style="width:100px"></v-combobox> -->
                                                <template>
                                                    <a-select v-model="ObjectForTrunkline.sizeCV_c2" v-if="showPlannerForm"  style="width:100%;color:rgb(134, 17, 1);font-weight:bold"   >
                                                        <a-select-option
                                                        v-for="item in selectionCvt"
                                                        :key="item.value"
                                                        :value="item.value"
                                                        >
                                                        {{ item.text }}
                                                        </a-select-option>
                                                    </a-select>
                                                </template>
                                            </td>
                                            


                                            <td ><a-input v-model="ObjectForTrunkline.quantity_c2"  :readOnly="!showPlannerForm"  :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense></a-input></td>
                                            <td width="13%"><a-input readOnly :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'"   outlined dense></a-input></td>
                                            
                                        </tr>                                       
                                    </tbody>

                                </v-simple-table>
                            </v-col>
                        </v-row>


                        
                    <v-toolbar class="mt-5" flat dense color="blue grey darken-4 white--text" tile>
                    <v-row align="center">
                        <v-col cols="10" class="d-flex justify-space-around">
                            <v-sheet flat dense color="blue grey darken-4 white--text" tile>
                                <strong>太陽光用</strong>
                            </v-sheet>
                        </v-col>
                    </v-row>
                    </v-toolbar>
                    <v-row align="center">
                        <v-col v-for="(item ,i) in forsunLight" :key="i">
                            <v-simple-table dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th v-for="(header , h) in Object.keys(item[0])" :key="h" class="text-center">
                                                {{header.toUpperCase()}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

 <!-- //NOTE 1st TABLE ROW                                        First table row -->
                                        <tr v-for="(body , b) in item" :key="b">
                                            <td rowspan="5" width="3%" class="text-center">参考値</td>
                                            <td   class="text-center">
                                                <v-layout>
                                                        <v-checkbox v-model="checkBox1" outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input  v-model="ObjectForTrunkline.ic_c2r3"  v-if="!showPlannerForm"  class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select v-model="ObjectForTrunkline.ic_c2r3"   v-if="showPlannerForm" style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                            v-for="item in selectionKiloWatts"
                                                                            :key="item.value"
                                                                            :value="item.value"
                                                                        >
                                                                        {{ item.text }}
                                                                        </a-select-option>
                                                                </a-select>
                                                        </template>
                                                </v-layout>  
                                            </td>


                                            <td   class="text-center">
                                                <v-layout>
                                                        <v-checkbox v-model="checkBox2" outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input  v-model="ObjectForTrunkline.size_c4r3"  v-if="!showPlannerForm"  class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select v-model="ObjectForTrunkline.size_c4r3"   v-if="showPlannerForm" style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                            v-for="item in selectionKiloWatts"
                                                                            :key="item.value"
                                                                            :value="item.value"
                                                                        >
                                                                        {{ item.text }}
                                                                        </a-select-option>
                                                                </a-select>
                                                        </template>
                                                </v-layout>  
                                            </td>

                                            <td class="text-center">
                                                
                                                <a-input v-model="ObjectForTrunkline.total_5r3"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="ObjectForTrunkline.total_5r3" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>

                                            <!-- //NOTE NEWLY RETURN 1-->
                                            <td>
                                                <a-input    v-model="ObjectForTrunkline.lpi_6r3"  class="fieldclass"    :readOnly="!showPlannerForm" v-if="!showPlannerForm"   outlined dense></a-input>
                                                <a-select v-model="ObjectForTrunkline.lpi_6r3" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>

                                            <td  class="text-center">
                                                
                                                <a-input v-model="ObjectForTrunkline.fl_c7r3"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="ObjectForTrunkline.fl_c7r3" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>

                                            <td rowspan="5">
                                                
                                                <a-input v-model="ObjectForTrunkline.eb_c1r1"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="ObjectForTrunkline.eb_c1r1" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>
                                        
                                        
                                        </tr>

 <!-- //NOTE 2nd TABLE ROW                                     2nd table row -->
                                        <tr>
                                            <td class="text-center">
                                                <v-layout>
                                                        <v-checkbox v-model="checkBox3" outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input  v-model="ObjectForTrunkline.ic_c2r5"  v-if="!showPlannerForm"  class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select v-model="ObjectForTrunkline.ic_c2r5"   v-if="showPlannerForm" style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                            v-for="item in selectionKiloWatts"
                                                                            :key="item.value"
                                                                            :value="item.value"
                                                                        >
                                                                        {{ item.text }}
                                                                        </a-select-option>
                                                                </a-select>
                                                        </template>
                                                </v-layout> 
                                            </td>
                                            
                                            <td>
                                                <v-layout>
                                                        <v-checkbox v-model="checkBox4" outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input  v-model="ObjectForTrunkline.size_c4r5"  v-if="!showPlannerForm"  class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select v-model="ObjectForTrunkline.size_c4r5"   v-if="showPlannerForm" style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                            v-for="item in selectionKiloWatts"
                                                                            :key="item.value"
                                                                            :value="item.value"
                                                                        >
                                                                        {{ item.text }}
                                                                        </a-select-option>
                                                                </a-select>
                                                        </template>
                                                </v-layout>  
                                            </td>


                                            <td class="text-center">
                                                
                                                <a-input v-model="ObjectForTrunkline.total_5r5"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="ObjectForTrunkline.total_5r5" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>

                                            <!-- //NOTE NEWLY RETURN 2-->
                                            <td>
                                                <a-input v-model="ObjectForTrunkline.lpi_6r5"     class="fieldclass"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" outlined dense></a-input>
                                                <a-select v-model="ObjectForTrunkline.lpi_6r5" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>

                                            <td class="text-center">
                                                
                                                <a-input v-model="ObjectForTrunkline.fl_c7r5"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="ObjectForTrunkline.fl_c7r5" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>
                                            
                                        </tr>



                                        
<!-- //NOTE 3rd TABLE ROW                                      3rd table row -->
                                    <tr>
                                            <td class="text-center">
                                                <v-layout>
                                                        <v-checkbox v-model="checkBox5" outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input  v-model="ObjectForTrunkline.ic_c2r6"  v-if="!showPlannerForm"  class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select v-model="ObjectForTrunkline.ic_c2r6"   v-if="showPlannerForm" style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                            v-for="item in selectionKiloWatts"
                                                                            :key="item.value"
                                                                            :value="item.value"
                                                                        >
                                                                        {{ item.text }}
                                                                        </a-select-option>
                                                                </a-select>
                                                        </template>
                                                </v-layout> 
                                            </td>
                                            
                                            <td>
                                                <v-layout>
                                                        <v-checkbox v-model="checkBox6" outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input  v-model="ObjectForTrunkline.size_c4r6"  v-if="!showPlannerForm"  class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select v-model="ObjectForTrunkline.size_c4r6"   v-if="showPlannerForm" style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                            v-for="item in selectionKiloWatts"
                                                                            :key="item.value"
                                                                            :value="item.value"
                                                                        >
                                                                        {{ item.text }}
                                                                        </a-select-option>
                                                                </a-select>
                                                        </template>
                                                </v-layout>  
                                            </td>


                                            <td class="text-center">
                                                
                                                <a-input v-model="ObjectForTrunkline.total_5r6"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="ObjectForTrunkline.total_5r6" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>

                                            <!-- //NOTE NEWLY RETURN 3-->
                                            <td>
                                                <a-input v-model="ObjectForTrunkline.lpi_6r6"     class="fieldclass"  :readOnly="!showPlannerForm"  v-if="!showPlannerForm" outlined dense></a-input>
                                                <a-select v-model="ObjectForTrunkline.lpi_6r6" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>

                                            <td width="20%" class="text-center">
                                                
                                                <a-input v-model="ObjectForTrunkline.fl_c7r6"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="ObjectForTrunkline.fl_c7r6" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>
                                            
                                        </tr>

<!-- //NOTE 4th TABLE ROW                                          4th table row -->
                                        <tr>
                                            <td class="text-center">
                                                <span style="font-size:16px">蓄電池自立</span>
                                            </td>
                                            <td width="18%" class="text-center">
                                                <v-layout>
                                                        <v-checkbox v-model="checkBox7"  outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input v-model="ObjectForTrunkline.size_c4r7" v-if="!showPlannerForm" class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select v-model="ObjectForTrunkline.size_c4r7" v-if="showPlannerForm"   style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                    v-for="item in selectionCv"
                                                                    :key="item.value"
                                                                    :value="item.value"
                                                                    >
                                                                    {{ item.text }}
                                                                    </a-select-option>
                                                            </a-select>
                                                        </template>
                                                </v-layout>  
                                            </td>
                                            <td ><a-input v-model="ObjectForTrunkline.total_5r7"  :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'"   :readOnly="!showPlannerForm" outlined dense></a-input></td>
                                            
                                            <!-- //NOTE NEWLY RETURN 4-->
                                            <td>
                                                <a-input v-model="ObjectForTrunkline.lpi_6r7"    class="fieldclass"   :readOnly="!showPlannerForm" v-if="!showPlannerForm"  outlined dense></a-input>
                                                <a-select v-model="ObjectForTrunkline.lpi_6r7" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>

                                            <td >
                                                <a-input v-model="ObjectForTrunkline.fl_c7r7"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="ObjectForTrunkline.fl_c7r7" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                  
                                                    <a-select-option :value="''">0</a-select-option>
                                                    <a-select-option v-for="num8 in 50" :key="num8" :value="num8">{{ num8 }}</a-select-option>
                                                </a-select>
                                            </td>
                                        </tr>



<!-- //NOTE 5th TABLE ROW                                      5th table row -->
                                        <tr>
                                            <td class="text-center">
                                                <span style="font-size:16px">給電アダプタ</span>
                                            </td>
                                            <td class="text-center">
                                                <v-layout>
                                                        <v-checkbox v-model="checkBox8"  outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input v-model="ObjectForTrunkline.newCv55" v-if="!showPlannerForm" class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select  v-model="ObjectForTrunkline.newCv55" v-if="showPlannerForm"   style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                    v-for="item in selectionCv"
                                                                    :key="item.value"
                                                                    :value="item.value"
                                                                    >
                                                                    {{ item.text }}
                                                                    </a-select-option>
                                                            </a-select>
                                                        </template>
                                                </v-layout>  
                                            </td>
                                            <td >
                                                <a-input v-model="ObjectForTrunkline.total_cv55_3" :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense></a-input>
                                                <a-select v-model="ObjectForTrunkline.total_cv55_3" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                
                                                <a-select-option :value="''">0</a-select-option>
                                                <a-select-option v-for="num9 in 50" :key="num9" :value="num9">{{ num9 }}</a-select-option>
                                            </a-select>
                                            </td>
                                            <td >
                                                <a-input v-model="ObjectForTrunkline.lpi_cv55_4" :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="ObjectForTrunkline.lpi_cv55_4" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                
                                                    <a-select-option :value="''">0</a-select-option>
                                                    <a-select-option v-for="num8 in 50" :key="num8" :value="num8">{{ num8 }}</a-select-option>
                                                </a-select>
                                            </td>

                                            <td >
                                                <a-input v-model="ObjectForTrunkline.fl_cv55_5" :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="ObjectForTrunkline.fl_cv55_5" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                
                                                    <a-select-option :value="''">0</a-select-option>
                                                    <a-select-option v-for="num10 in 50" :key="num10" :value="num10">{{ num10 }}</a-select-option>
                                                </a-select>
                                            </td>
                                        </tr>

                                    
                                        

                                    </tbody>

                
                                    </template>
                                </v-simple-table>
                            </v-col>
                    </v-row>

                      

                        <!-- NOTE TABLE FOR NISETAI -->
                        <v-toolbar class="mt-7"  dense color="red darken-4 white--text" tile v-if="ObjectForTrunkline.nisetai > 1 && nisetaiData.nisetai_form">
                            <v-toolbar-title style="margin-left:40%;font-size:25px">2世帯電気工事</v-toolbar-title>
                        </v-toolbar>
                    
                        <v-toolbar class="mt-1" flat dense color="blue grey darken-4 white--text" tile v-if="ObjectForTrunkline.nisetai > 1 && nisetaiData.nisetai_form">
                            <v-row align="center">
                                <v-col cols="11" class="d-flex justify-space-around">
                                    <v-sheet flat dense color="blue grey darken-4 white--text" tile>
                                        幹線
                                    </v-sheet>
                                </v-col>
                                <v-col cols="1" class="d-flex justify-space-around">
                                    <v-sheet flat dense color="blue grey darken-4 white--text" tile>
                                    
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-toolbar>
                        <v-row v-if="ObjectForTrunkline.nisetai > 1 && nisetaiData.nisetai_form">
                            <v-col v-for="(item ,i) in mainLine" :key="i">
                                <v-simple-table dense>
                                    <thead>
                                        <tr>
                                            <th colspan="2"></th>
                                            <th v-for="(header , h) in Object.keys(item[0])" :key="h" class="text-center">
                                                
                                                {{header.toUpperCase()}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(body , b) in item" :key="b">
                                            <td rowspan="3">参考値</td>
                                            <td >電灯</td>
                                            <td  class="text-center">
                                                <a-input v-model="nisetaiData.cc_c1"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense></a-input>
                                                <!-- <v-combobox v-model="ObjectForTrunkline.cc_c1" v-else :items="selectionCategory" :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense hide-details style="width:100px"></v-combobox> -->
                                                <template>
                                                    <a-select v-model="nisetaiData.cc_c1"  @change="UpdateCc1(ObjectForTrunkline.cc_c1)" v-if="showPlannerForm"  style="width:100%;color:rgb(134, 17, 1);font-weight:bold"   >
                                                        <a-select-option
                                                        v-for="item in selectionCategory"
                                                        :key="item.value"
                                                        :value="item.value"
                                                        >
                                                        {{ item.text }}
                                                        </a-select-option>
                                                    </a-select>
                                                </template>
                                            </td> 
                                                                
                                            <td ><a-input v-model="nisetaiData.kansen_blankc1"  :readOnly="!showPlannerForm"  :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense></a-input></td>
                                            <td >
                                                <a-input v-model="nisetaiData.sizeCV_c1" v-if="!showPlannerForm" :readOnly="!showPlannerForm"  :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense></a-input>
                                                <!-- <v-combobox v-model="ObjectForTrunkline.sizeCV_c1" v-else :items="selectionCategory" :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense hide-details style="width:100px"></v-combobox> -->
                                                <template>
                                                    <a-select v-model="nisetaiData.sizeCV_c1" v-if="showPlannerForm"  style="width:100%;color:rgb(134, 17, 1);font-weight:bold"   >
                                                        <a-select-option
                                                        v-for="item in selectionCvt"
                                                        :key="item.value"
                                                        :value="item.value"
                                                        >
                                                        {{ item.text }}
                                                        </a-select-option>
                                                    </a-select>
                                                </template>
                                            </td>
                                            <td ><a-input v-model="nisetaiData.quantity_c1" :readOnly="!showPlannerForm"  :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense></a-input></td>
                                        
                                            <td >
                                            
                                                <a-input v-model="nisetaiData.wa_c1"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="nisetaiData.wa_c1" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option v-for="num1 in 50" :key="num1" :value="num1">{{ num1 }}</a-select-option>
                                                </a-select>
                                            </td>
                                            <td rowspan="2">
                                                <a-input v-model="nisetaiData.eb_c1"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense style="text-align:center;height:50px"></a-input>
                                                <a-select v-model="nisetaiData.eb_c1" v-if="showPlannerForm" style="width:80%" class="fieldclass">
                                                    <a-select-option v-for="number in 10" :key="number" :value="number">{{ number }}</a-select-option>
                                                </a-select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center" v-if="nisetaiData.type !='Zenryou'">余剰</td>
                                            <td class="text-center" v-else>全量</td>
                                            <td width="13%"><v-layout style="border:1px solid rgb(170, 170, 170);border-radius: 5px;">
                                                <a-input v-model="nisetaiData.cc_c2" :readOnly="!showPlannerForm" :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'"  outlined dense  style="width:80%"></a-input>
                                                                        <span style="margin-top:15%;font-weight:bold" >kW</span>
                                                            </v-layout>
                                            </td>


                                            <td width="13%"><a-input readOnly :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'"   outlined dense></a-input></td>
                                            
                                            <td width="13%" v-if="nisetaiData.type != 'Zenryou'"><a-input readOnly :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'"   outlined dense></a-input></td>

                                            <td v-else>
                                                <a-input v-model="nisetaiData.sizeCV_c2" v-if="!showPlannerForm" :readOnly="!showPlannerForm"  :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense></a-input>
                                                <!-- <v-combobox v-model="ObjectForTrunkline.sizeCV_c1" v-else :items="selectionCategory" :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense hide-details style="width:100px"></v-combobox> -->
                                                <template>
                                                    <a-select v-model="nisetaiData.sizeCV_c2" v-if="showPlannerForm"  style="width:100%;color:rgb(134, 17, 1);font-weight:bold"   >
                                                        <a-select-option
                                                        v-for="item in selectionCvt"
                                                        :key="item.value"
                                                        :value="item.value"
                                                        >
                                                        {{ item.text }}
                                                        </a-select-option>
                                                    </a-select>
                                                </template>
                                            </td>


                                            <td ><a-input v-model="nisetaiData.quantity_c2"  :readOnly="!showPlannerForm"  :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'" outlined dense></a-input></td>
                                            <td width="13%"><a-input readOnly :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'"   outlined dense></a-input></td>
                                            
                                        </tr>                                       
                                    </tbody>

                                </v-simple-table>
                            </v-col>
                        </v-row>

                    <v-toolbar class="mt-5" flat dense color="blue grey darken-4 white--text" tile v-if="ObjectForTrunkline.nisetai > 1 && nisetaiData.nisetai_form">
                        <v-row align="center">
                            <v-col cols="10" class="d-flex justify-space-around">
                                <v-sheet flat dense color="blue grey darken-4 white--text" tile>
                                    <strong>太陽光用</strong>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </v-toolbar>
                    <v-row align="center" v-if="ObjectForTrunkline.nisetai > 1 && nisetaiData.nisetai_form">
                        <v-col v-for="(item ,i) in forsunLight" :key="i">
                            <v-simple-table dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th v-for="(header , h) in Object.keys(item[0])" :key="h" class="text-center">
                                                {{header.toUpperCase()}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

 <!-- //NOTE 1st TABLE ROW                                        First table row -->
                                        <tr v-for="(body , b) in item" :key="b">
                                            <td rowspan="7" width="3%" class="text-center">参考値</td>
                                            <td width="20%"  class="text-center">
                                                <v-layout>
                                                        <v-checkbox v-model="checkBox1" outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input  v-model="nisetaiData.ic_c2r3"  v-if="!showPlannerForm"  class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select v-model="nisetaiData.ic_c2r3"   v-if="showPlannerForm" style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                            v-for="item in selectionKiloWatts"
                                                                            :key="item.value"
                                                                            :value="item.value"
                                                                        >
                                                                        {{ item.text }}
                                                                        </a-select-option>
                                                                </a-select>
                                                        </template>
                                                </v-layout>  
                                            </td>


                                            <td width="20%"  class="text-center">
                                                <v-layout>
                                                        <v-checkbox v-model="checkBox2" outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input  v-model="nisetaiData.size_c4r3"  v-if="!showPlannerForm"  class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select v-model="nisetaiData.size_c4r3"   v-if="showPlannerForm" style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                            v-for="item in selectionKiloWatts"
                                                                            :key="item.value"
                                                                            :value="item.value"
                                                                        >
                                                                        {{ item.text }}
                                                                        </a-select-option>
                                                                </a-select>
                                                        </template>
                                                </v-layout>  
                                            </td>

                                            <td width="20%" class="text-center">
                                                
                                                <a-input v-model="nisetaiData.total_5r3"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="nisetaiData.total_5r3" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>

                                            <td width="20%" class="text-center">
                                                
                                                <a-input v-model="nisetaiData.fl_c7r3"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="nisetaiData.fl_c7r3" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>

                                            <td rowspan="5">
                                                
                                                <a-input v-model="nisetaiData.eb_c1r1"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="nisetaiData.eb_c1r1" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>
                                        
                                        
                                        </tr>

 <!-- //NOTE 2nd TABLE ROW                                     2nd table row -->
                                        <tr>
                                            <td width="20%" class="text-center">
                                                <v-layout>
                                                        <v-checkbox v-model="checkBox3" outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input  v-model="nisetaiData.ic_c2r5"  v-if="!showPlannerForm"  class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select v-model="nisetaiData.ic_c2r5"   v-if="showPlannerForm" style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                            v-for="item in selectionKiloWatts"
                                                                            :key="item.value"
                                                                            :value="item.value"
                                                                        >
                                                                        {{ item.text }}
                                                                        </a-select-option>
                                                                </a-select>
                                                        </template>
                                                </v-layout> 
                                            </td>
                                            
                                            <td>
                                                <v-layout>
                                                        <v-checkbox v-model="checkBox4" outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input  v-model="nisetaiData.size_c4r5"  v-if="!showPlannerForm"  class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select v-model="nisetaiData.size_c4r5"   v-if="showPlannerForm" style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                            v-for="item in selectionKiloWatts"
                                                                            :key="item.value"
                                                                            :value="item.value"
                                                                        >
                                                                        {{ item.text }}
                                                                        </a-select-option>
                                                                </a-select>
                                                        </template>
                                                </v-layout>  
                                            </td>


                                            <td width="14%" class="text-center">
                                                
                                                <a-input v-model="nisetaiData.total_5r5"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="nisetaiData.total_5r5" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>

                                            <td width="14%" class="text-center">
                                                
                                                <a-input v-model="nisetaiData.fl_c7r5"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="nisetaiData.fl_c7r5" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>
                                            
                                        </tr>



                                        
<!-- //NOTE 3rd TABLE ROW                                      3rd table row -->
                                    <tr>
                                            <td width="20%" class="text-center">
                                                <v-layout>
                                                        <v-checkbox v-model="checkBox5" outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input  v-model="nisetaiData.ic_c2r6"  v-if="!showPlannerForm"  class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select v-model="nisetaiData.ic_c2r6"   v-if="showPlannerForm" style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                            v-for="item in selectionKiloWatts"
                                                                            :key="item.value"
                                                                            :value="item.value"
                                                                        >
                                                                        {{ item.text }}
                                                                        </a-select-option>
                                                                </a-select>
                                                        </template>
                                                </v-layout> 
                                            </td>
                                            
                                            <td>
                                                <v-layout>
                                                        <v-checkbox v-model="checkBox6" outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input  v-model="nisetaiData.size_c4r6"  v-if="!showPlannerForm"  class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select v-model="nisetaiData.size_c4r6"   v-if="showPlannerForm" style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                            v-for="item in selectionKiloWatts"
                                                                            :key="item.value"
                                                                            :value="item.value"
                                                                        >
                                                                        {{ item.text }}
                                                                        </a-select-option>
                                                                </a-select>
                                                        </template>
                                                </v-layout>  
                                            </td>


                                            <td width="14%" class="text-center">
                                                
                                                <a-input v-model="nisetaiData.total_5r6"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="nisetaiData.total_5r6" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>

                                            <td width="14%" class="text-center">
                                                
                                                <a-input v-model="nisetaiData.fl_c7r6"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="nisetaiData.fl_c7r6" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                    <a-select-option :value ="''">0</a-select-option>
                                                    <a-select-option v-for="num2 in 50" :key="num2" :value="num2">{{ num2 }}</a-select-option>
                                                </a-select>
                                            </td>
                                            
                                        </tr>

<!-- //NOTE 4th TABLE ROW                                          4th table row -->
                                        <tr>
                                            <td width="20%" class="text-center">
                                                <span style="font-size:16px">蓄電池自立</span>
                                            </td>
                                            <td width="18%" class="text-center">
                                                <v-layout>
                                                        <v-checkbox v-model="checkBox7"  outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input v-model="nisetaiData.size_c4r7" v-if="!showPlannerForm" class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select v-model="nisetaiData.size_c4r7" v-if="showPlannerForm"   style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                    v-for="item in selectionCv"
                                                                    :key="item.value"
                                                                    :value="item.value"
                                                                    >
                                                                    {{ item.text }}
                                                                    </a-select-option>
                                                            </a-select>
                                                        </template>
                                                </v-layout>  
                                            </td>
                                            <td width="13%"><a-input v-model="nisetaiData.total_5r7"  :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'"   :readOnly="!showPlannerForm" outlined dense></a-input></td>
                                   
                                            <td width="13%">
                                                <a-input v-model="nisetaiData.fl_c7r7"  :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select v-model="nisetaiData.fl_c7r7" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                  
                                                    <a-select-option :value="''">0</a-select-option>
                                                    <a-select-option v-for="num8 in 50" :key="num8" :value="num8">{{ num8 }}</a-select-option>
                                                </a-select>
                                            </td>
                                        </tr>



<!-- //NOTE 5th TABLE ROW                                      5th table row -->
                                        <tr>
                                            <td width="20%" class="text-center">
                                                <span style="font-size:16px">給電アダプタ</span>
                                            </td>

                                            <td width="20%" class="text-center">
                                                <v-layout>
                                                        <v-checkbox v-model="checkBoxNesitai8"  outlined dense hide-details :disabled="!showPlannerForm"></v-checkbox>
                                                        <a-input v-model="nisetaiData.newCv55" placeholder="No Data1"  v-if="!showPlannerForm" class="fieldclass" style="width:100%" outlined dense readOnly></a-input>
                                                        <template>
                                                            <a-select  v-model="nisetaiData.newCv55" v-if="showPlannerForm"   style="width:100%;color:rgb(134, 17, 1);font-weight:bold" outlined dense>
                                                                <a-select-option
                                                                    v-for="item in selectionCv"
                                                                    :key="item.value"
                                                                    :value="item.value"
                                                                    >
                                                                    {{ item.text }}
                                                                    </a-select-option>
                                                            </a-select>
                                                        </template>
                                                </v-layout>  
                                            </td>   



                                            <td width="13%"><a-input v-model="nisetaiData.total_cv55_3"   :class="showPlannerForm ? 'fieldclass2' : 'fieldclass'"   :readOnly="!showPlannerForm" outlined dense></a-input></td>
                                            <td width="13%">
                                                <a-input v-model="nisetaiData.fl_cv55_5" :readOnly="!showPlannerForm" v-if="!showPlannerForm" class="fieldclass" outlined dense ></a-input>
                                                <a-select  v-model="nisetaiData.fl_cv55_5" v-if="showPlannerForm" style="width:100%" class="fieldclass">
                                                
                                                    <a-select-option :value="''">0</a-select-option>
                                                    <a-select-option v-for="num8 in 50" :key="num8" :value="num8">{{ num8 }}</a-select-option>
                                                </a-select>
                                            </td>
                                        </tr>

                                    
                                        

                                    </tbody>

                
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>





<!-- NOTE END TO NISETAI TABLE -->








                        <v-card flat style="margin-top:1%;">
                            <center>
                                <span><b>*万一、支給できないものがある場合は、FAX返信でご連絡させていただきますので、貴社にて手配を行なってください。</b></span>
                                <span><b>*幹線は1メートル単位での発注となります。1メートル未満の依頼をされた場合、繰上げして発注します。</b></span>
                            </center>    
                        </v-card>
                    
                    </v-card>    
                </v-card>


                <v-card-text>
                    
                    <!-- ANCHOR 2nd TABLE /// Table for 幹線 -->

                

                    <center>
                        <v-btn
                            :hidden="this.noChangesInOrder != null || this.withChangeInOrder != null || ObjectForTrunkline.order_status == 'No Request'"
                            v-if="!isChangeOrderConfirmed"
                            width="250px" 
                            color="warning" 
                            class="mt-4" 
                            rounded
                            @click="firstRequestOrderWithChangesDate()"
                        >
                            
                            変更依頼
                        </v-btn>    
                        <!-- CHANGE ORDER -->
                        <v-btn
                            :hidden="this.noChangesInOrder != null || this.withChangeInOrder != null || ObjectForTrunkline.order_status == 'No Request'"
                            v-if="!isChangeOrderConfirmed "
                            width="250px" 
                            color="success" 
                            class="mt-4" 
                            rounded
                            style="margin-left:5%" 
                            @click="firstRequestOrderWithoutChangesDate()">
                            発注する
                        </v-btn> 
                        <!-- SEND ORDER -->
                        <v-btn
                            :hidden="this.noChangesInOrder != null || this.withChangeInOrder != null"
                            v-if="isChangeOrderConfirmed "
                            width="250px"
                            color="success"
                            class="mt-4"
                            rounded
                            style="margin-left:5%"
                            @click="saveOrderChanges()" 
                        >
                            保存
                        </v-btn>
                        <!-- SAVE -->
                        <v-btn
                            :hidden="this.noChangesInOrder != null || this.withChangeInOrder != null"
                            v-if="isChangeOrderConfirmed"
                            width="250px"
                            color="error"
                            class="mt-4"
                            rounded
                            style="margin-left:5%"
                            @click="cancelChanges()" 
                        
                        >
                            キャンセル
                        </v-btn>
                        <!-- CANCEL -->
                        <!-- <span v-if="!ObjectForTrunkline.firstReq_with_changes" style="color:red;font-weight:bold;font-size:20px">Order Already Sent!</span> -->
                    </center>
                </v-card-text>
            </v-card>
        </v-dialog>

    
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { bus } from '../main'
import { message } from "ant-design-vue";
import {ArraymainLineInSubcon ,ArrayforsunLightinSubcon} from './Reference'
const key = 'updatable';
    export default {
        props: [
            'forSubconReq',
            'dataFromLocal_Parent',
            
        ],
        data(){
            return{
                
                nisetaiData:{},                
                dataFromParent:[],
                checkBox1:false,
                checkBox2:false,
                checkBox3:false,
                checkBox4:false,
                checkBox5:false,
                checkBox6:false,
                checkBox7:false,
                checkBox8:false,
                checkBox9:false,
                checkBox10:false,
                checkBox11:false,
                checkBox12:false,
                checkBox13:false,
                
        
                dataBeforeOrder:{},
                ObjectForTrunkline:{
                    fl_c1: "",
                    fl_c7r2: "",
                    fl_c7r3: "",
                    fl_c7r4: "",
                    fl_c7r5: "",
                    fl_c7r6: "",
                    fl_c7r7: "", 
                },
                dataForTrunkline:[],
                mainLine : ArraymainLineInSubcon ,            
                forsunLight: ArrayforsunLightinSubcon,
                secondRequestFormDialog:false,
                subconFirstRequest:[],
                plannersData:{},
                myObject:[],
                isChangeOrderConfirmed: false,
                orderWithoutChanges:'',
                showImage1: false,
                showImage2: false,
                showImage3: false,
                showImage4: false,
                showPlannerForm:false,

                noChangesInOrder:'',
                withChangeInOrder:'',
                selectionCategory : [
                    { value: '30A', text: '30A' },
                    { value: '40A', text: '40A' },
                    { value: '50A', text: '50A' },
                    { value: '60A', text: '60A' },
                    { value: '10KVA', text: '10KVA' },
                    { value: '12KVA', text: '12KVA' },
                    { value: '15KVA', text: '15KVA' },
                    { value: '18KVA', text: '18KVA' },
                    { value: '19KVA', text: '19KVA' },
                    { value: '20KVA', text: '20KVA' },
                    { value: '21KVA', text: '21KVA' },
                    { value: '22KVA', text: '22KVA' },
                    { value: '23KVA', text: '23KVA' },
                    { value: '24KVA', text: '24KVA' },
                    { value: '25KVA', text: '25KVA' },
                ],

                selectionCvt: [
                    { value: 'CV8', text: 'CV8' },
                    { value: 'CVT14', text: 'CVT14' },
                    { value: 'CVT22', text: 'CVT22' },
                    { value: 'CVT38', text: 'CVT38' },
                    { value: 'CVT60', text: 'CVT60' },
                ],

                selectionKiloWatts :  [
                    { value: '3.0 KW', text: '3.0 KW' },
                    { value: '4.0 KW', text: '4.0 KW' },
                    { value: '5.5 KW', text: '5.5 KW' },
                    { value: '5.9 KW', text: '5.9 KW' },
                    { value: '8.0 KW', text: '8.0 KW' },
                    { value: '9.9 KW', text: '9.9 KW' },  
                ],
                selectionCv :  [
                    { value: 'CV 5.5', text: 'CV 5.5' },
                    { value: 'CV 8', text: 'CV 8' },
                    { value: 'CV 14', text: 'CV 14' },
                    { value: 'CV 22', text: 'CV 22'},
                ],

                


            }
        },
methods:{
    UpdateCc1() {
        const c1ToBlankC1Map = {
            "30A": "30A",
            "40A": "40A",
            "50A": "50A",
            "60A": "60A",
            "8KVA": "40A",
            "10KVA": "50A",
            "12KVA": "60A",
            "15KVA": "75A",
            "18KVA": "100A",
            "19KVA": "100A",
            "20KVA": "100A",
            "21KVA": "100A",
            "22KVA": "100A",
            "23KVA": "100A",
            "24KVA": "100A",
            "25KVA": "100A"
        };
            const cc_c1 = this.ObjectForTrunkline.cc_c1;
            this.ObjectForTrunkline.kansen_blankc1 = c1ToBlankC1Map[cc_c1] || "";
    },
        

    cancelChanges(){
        this.secondRequestFormDialog = false
        window.location.reload()
    },
    calculate(){
        let sum = 0;
        let numbers = this.ObjectForTrunkline.Value_c1.split("+");
            for(let i = 0;i < numbers.length; i++){
                sum += parseInt(numbers[i]);
            }
                this.ObjectForTrunkline.wa_c1 = sum ? sum : ''

    },

    firstRequestOrderWithChangesDate(){
        Swal.fire({
                icon:'question',
                title: 'ご注文を変更しますか？',
                text:'変更を希望する場合は確認してください。変更を希望しない場合はキャンセルしてください。',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                showCancelButton: true,
                confirmButtonText: '確認する',
                cancelButtonText : 'キャンセル',
                allowOutsideClick: () => {
                const popup = this.$swal.getPopup()
                popup.classList.remove('swal2-show')
                return false
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.isChangeOrderConfirmed = true,
                    message.success({
                                bottom: '100px',
                                duration: 2,
                                maxCount: 3,
                                text:'You can now change order!',
                                });
                        this.dataBeforeOrder = {...this.ObjectForTrunkline}       
                        this.showPlannerForm = !this.showPlannerForm; 
                                                            
                }else{
                    return false
                }
            })                     
    
        },



    firstRequestOrderWithoutChangesDate(){
            let latestIndex = this.myObject.length - 1;
            let latestRecord = this.myObject[latestIndex];

            const today = new Date();
            const year = today.getFullYear();
            const month = (today.getMonth() + 1).toString().padStart(2, '0');
            const day = today.getDate().toString().padStart(2, '0');
            const date = `${year}-${month}-${day}`;

            const hours = today.getHours().toString().padStart(2, '0');
            const minutes = today.getMinutes().toString().padStart(2, '0');
            const seconds = today.getSeconds().toString().padStart(2, '0');
            const formattedTime = `${hours}:${minutes}:${seconds}`;

            const dateTime = `${date} | ${formattedTime}`;
           
            var dateToday = new Date(date);
            var dueDate = new Date(this.ObjectForTrunkline.lessjoutouDate);

        if(this.checkBox13){
                    if(this.checkBox11 && this.checkBox12){
                        this.ObjectForTrunkline.size_c3r6 = 1
                        this.ObjectForTrunkline.ic_c1r6 = 1
                    }else if(this.checkBox10 && this.checkBox9){
                        this.ObjectForTrunkline.ic_c1r5 = 1
                        this.ObjectForTrunkline.size_c3r5 = 1
                    }else if(this.checkBox8 && this.checkBox7){
                        this.ObjectForTrunkline.ic_c1r4 = 1
                        this.ObjectForTrunkline.size_c3r4 = 1
                    }else if(this.checkBox6 && this.checkBox5){
                        this.ObjectForTrunkline.ic_c1r3 = 1
                        this.ObjectForTrunkline.size_c3r3 = 1
                    }else if(this.checkBox4 && this.checkBox3){
                        this.ObjectForTrunkline.ic_c1r2 = 1
                        this.ObjectForTrunkline.size_c3r2 = 1
                    }else if(this.checkBox2 && this.checkBox1){
                        this.ObjectForTrunkline.ic_cb1 = 1
                        this.ObjectForTrunkline.size_cb1 = 1
                    } 
            this.ObjectForTrunkline.size_c3r7 = 1
        }else if(this.checkBox11 && this.checkBox12){
                    if(this.checkBox10 && this.checkBox9){
                        this.ObjectForTrunkline.ic_c1r5 = 1
                        this.ObjectForTrunkline.size_c3r5 = 1
                    }else if(this.checkBox8 && this.checkBox7){
                        this.ObjectForTrunkline.ic_c1r4 = 1
                        this.ObjectForTrunkline.size_c3r4 = 1
                    }else if(this.checkBox6 && this.checkBox5){
                        this.ObjectForTrunkline.ic_c1r3 = 1
                        this.ObjectForTrunkline.size_c3r3 = 1
                    }else if(this.checkBox4 && this.checkBox3){
                        this.ObjectForTrunkline.ic_c1r2 = 1
                        this.ObjectForTrunkline.size_c3r2 = 1
                    }else if(this.checkBox2 && this.checkBox1){
                        this.ObjectForTrunkline.ic_cb1 = 1
                        this.ObjectForTrunkline.size_cb1 = 1
                    }else if(this.checkBox13){
                        this.ObjectForTrunkline.size_c3r7 = 1   
                    } 
            this.ObjectForTrunkline.size_c3r6 = 1
            this.ObjectForTrunkline.ic_c1r6 = 1
        }else if(this.checkBox10 && this.checkBox9){
                    if(this.checkBox11 && this.checkBox12){
                        this.ObjectForTrunkline.size_c3r6 = 1
                        this.ObjectForTrunkline.ic_c1r6 = 1
                    }else if(this.checkBox8 && this.checkBox7){
                        this.ObjectForTrunkline.ic_c1r4 = 1
                        this.ObjectForTrunkline.size_c3r4 = 1
                    }else if(this.checkBox6 && this.checkBox5){
                        this.ObjectForTrunkline.ic_c1r3 = 1
                        this.ObjectForTrunkline.size_c3r3 = 1
                    }else if(this.checkBox4 && this.checkBox3){
                        this.ObjectForTrunkline.ic_c1r2 = 1
                        this.ObjectForTrunkline.size_c3r2 = 1
                    }else if(this.checkBox2 && this.checkBox1){
                        this.ObjectForTrunkline.ic_cb1 = 1
                        this.ObjectForTrunkline.size_cb1 = 1
                    }else if(this.checkBox13){
                        this.ObjectForTrunkline.size_c3r7 = 1   
                    } 
            this.ObjectForTrunkline.ic_c1r5 = 1
            this.ObjectForTrunkline.size_c3r5 = 1
        }else if(this.checkBox8 && this.checkBox7){
                    if(this.checkBox11 && this.checkBox12){
                        this.ObjectForTrunkline.size_c3r6 = 1
                        this.ObjectForTrunkline.ic_c1r6 = 1
                    }else if(this.checkBox10 && this.checkBox9){
                        this.ObjectForTrunkline.ic_c1r5 = 1
                        this.ObjectForTrunkline.size_c3r5 = 1
                    }else if(this.checkBox6 && this.checkBox5){
                        this.ObjectForTrunkline.ic_c1r3 = 1
                        this.ObjectForTrunkline.size_c3r3 = 1
                    }else if(this.checkBox4 && this.checkBox3){
                        this.ObjectForTrunkline.ic_c1r2 = 1
                        this.ObjectForTrunkline.size_c3r2 = 1
                    }else if(this.checkBox2 && this.checkBox1){
                        this.ObjectForTrunkline.ic_cb1 = 1
                        this.ObjectForTrunkline.size_cb1 = 1
                    }else if(this.checkBox13){
                        this.ObjectForTrunkline.size_c3r7 = 1   
                    }else if(this.checkBox13){
                        this.ObjectForTrunkline.size_c3r7 = 1   
                    }
            this.ObjectForTrunkline.ic_c1r4 = 1
            this.ObjectForTrunkline.size_c3r4 = 1
        }else if(this.checkBox6 && this.checkBox5){
                    if(this.checkBox11 && this.checkBox12){
                        this.ObjectForTrunkline.size_c3r6 = 1
                        this.ObjectForTrunkline.ic_c1r6 = 1
                    }else if(this.checkBox10 && this.checkBox9){
                        this.ObjectForTrunkline.ic_c1r5 = 1
                        this.ObjectForTrunkline.size_c3r5 = 1
                    }else if(this.checkBox8 && this.checkBox7){
                        this.ObjectForTrunkline.ic_c1r4 = 1
                        this.ObjectForTrunkline.size_c3r4 = 1
                    }else if(this.checkBox4 && this.checkBox3){
                        this.ObjectForTrunkline.ic_c1r2 = 1
                        this.ObjectForTrunkline.size_c3r2 = 1
                    }else if(this.checkBox2 && this.checkBox1){
                        this.ObjectForTrunkline.ic_cb1 = 1
                        this.ObjectForTrunkline.size_cb1 = 1
                    }else if(this.checkBox13){
                        this.ObjectForTrunkline.size_c3r7 = 1   
                    } 
            this.ObjectForTrunkline.ic_c1r3 = 1
            this.ObjectForTrunkline.size_c3r3 = 1
        }else if(this.checkBox4 && this.checkBox3){
                    if(this.checkBox11 && this.checkBox12){
                        this.ObjectForTrunkline.size_c3r6 = 1
                        this.ObjectForTrunkline.ic_c1r6 = 1
                    }else if(this.checkBox10 && this.checkBox9){
                        this.ObjectForTrunkline.ic_c1r5 = 1
                        this.ObjectForTrunkline.size_c3r5 = 1
                    }else if(this.checkBox8 && this.checkBox7){
                        this.ObjectForTrunkline.ic_c1r4 = 1
                        this.ObjectForTrunkline.size_c3r4 = 1
                    }else if(this.checkBox6 && this.checkBox5){
                        this.ObjectForTrunkline.ic_c1r3 = 1
                        this.ObjectForTrunkline.size_c3r3 = 1
                    }else if(this.checkBox2 && this.checkBox1){
                        this.ObjectForTrunkline.ic_cb1 = 1
                        this.ObjectForTrunkline.size_cb1 = 1
                    }else if(this.checkBox13){
                        this.ObjectForTrunkline.size_c3r7 = 1   
                    }
            this.ObjectForTrunkline.ic_c1r2 = 1
            this.ObjectForTrunkline.size_c3r2 = 1
        }else if(this.checkBox2 && this.checkBox1){
                    if(this.checkBox11 && this.checkBox12){
                        this.ObjectForTrunkline.size_c3r6 = 1
                        this.ObjectForTrunkline.ic_c1r6 = 1
                    }else if(this.checkBox10 && this.checkBox9){
                        this.ObjectForTrunkline.ic_c1r5 = 1
                        this.ObjectForTrunkline.size_c3r5 = 1
                    }else if(this.checkBox8 && this.checkBox7){
                        this.ObjectForTrunkline.ic_c1r4 = 1
                        this.ObjectForTrunkline.size_c3r4 = 1
                    }else if(this.checkBox6 && this.checkBox5){
                        this.ObjectForTrunkline.ic_c1r3 = 1
                        this.ObjectForTrunkline.size_c3r3 = 1
                    }else if(this.checkBox4 && this.checkBox3){
                        this.ObjectForTrunkline.ic_c1r2 = 1
                        this.ObjectForTrunkline.size_c3r2 = 1
                    }else if(this.checkBox13){
                        this.ObjectForTrunkline.size_c3r7 = 1   
                    } 
            this.ObjectForTrunkline.ic_cb1 = 1
            this.ObjectForTrunkline.size_cb1 = 1
        }  
                                                        
        let nisetaiNoChangesDataOrder = {
            code_number: this.nisetaiData.code_number,
            first_request_date : date,
            cc_c1: this.nisetaiData.cc_c1,
            kansen_blankc1: this.nisetaiData.kansen_blankc1,
            sizeCV_c1: this.nisetaiData.sizeCV_c1,
            quantity_c1: this.nisetaiData.quantity_c1,
            Value_c1: this.nisetaiData.Value_c1,
            wa_c1: this.nisetaiData.wa_c1,
            eb_c1: this.nisetaiData.eb_c1,
            cc_c2: this.nisetaiData.cc_c2,
            ic_c2: this.nisetaiData.ic_c2,
            size_cvt1: this.nisetaiData.size_cvt1,
            total_c1: this.nisetaiData.total_c1,
            lpi_c1: this.nisetaiData.lpi_c1,
            fl_c1: this.nisetaiData.fl_c1,
            eb_c1r1: this.nisetaiData.eb_c1r1,
            ic_c2r2: this.nisetaiData.ic_c2r2,
            size_c4r2: this.nisetaiData.size_c4r2,
            total_5r2: this.nisetaiData.total_5r2,
            lpi_6r2: this.nisetaiData.lpi_6r2,
            fl_c7r2: this.nisetaiData.fl_c7r2,
            ic_c2r3: this.nisetaiData.ic_c2r3,
            size_c4r3: this.nisetaiData.size_c4r3,
            total_5r3: this.nisetaiData.total_5r3,
            lpi_6r3: this.nisetaiData.lpi_6r3,
            fl_c7r3: this.nisetaiData.fl_c7r3,
            ic_c2r4: this.nisetaiData.ic_c2r4,
            sizeCV_c4r4: this.nisetaiData.sizeCV_c4r4,
            total_5r4: this.nisetaiData.total_5r4,
            lpi_6r4: this.nisetaiData.lpi_6r4,
            fl_c7r4: this.nisetaiData.fl_c7r4,
            ic_c2r5: this.nisetaiData.ic_c2r5,
            size_c4r5: this.nisetaiData.size_c4r5,
            total_5r5: this.nisetaiData.total_5r5,
            lpi_6r5: this.nisetaiData.lpi_6r5,
            fl_c7r5: this.nisetaiData.fl_c7r5,
            ic_c2r6: this.nisetaiData.ic_c2r6,
            size_c4r6: this.nisetaiData.size_c4r6,
            total_5r6: this.nisetaiData.total_5r6,
            lpi_6r6: this.nisetaiData.lpi_6r6,
            fl_c7r6: this.nisetaiData.fl_c7r6,
            size_c4r7: this.nisetaiData.size_c4r7,
            total_5r7: this.nisetaiData.total_5r7,
            lpi_6r7: this.nisetaiData.lpi_6r7,
            fl_c7r7: this.nisetaiData.fl_c7r7,
                ic_cb1   : this.nisetaiData.ic_cb1,
                ic_c1r2   : this.nisetaiData.ic_c1r2,
                ic_c1r3   : this.nisetaiData.ic_c1r3,
                ic_c1r4   : this.nisetaiData.ic_c1r4,
                ic_c1r5   : this.nisetaiData.ic_c1r5,
                size_c3r6   : this.nisetaiData.size_c3r6,
                ic_c1r6   : this.nisetaiData.ic_c1r6,
                size_cb1   : this.nisetaiData.size_cb1,
                size_c3r2   : this.nisetaiData.size_c3r2,
                size_c3r3   : this.nisetaiData.size_c3r3,
                size_c3r4   : this.nisetaiData.size_c3r4,
                size_c3r5   : this.nisetaiData.size_c3r5,
                size_c3r7   : this.nisetaiData.size_c3r7,
            id: this.nisetaiData.id,
            newCv55 : this.nisetaiData.newCv55,
            total_cv55_3 : this.nisetaiData.total_cv55_3,
            lpi_cv55_4 : this.nisetaiData.lpi_cv55_4,
            fl_cv55_5:this.nisetaiData.fl_cv55_5
            };
            let objectForOrder = {
                            code_number: this.ObjectForTrunkline.code_number,
                            first_request_date : date,
                            cc_c1: this.ObjectForTrunkline.cc_c1,
                            kansen_blankc1: this.ObjectForTrunkline.kansen_blankc1,
                            sizeCV_c1: this.ObjectForTrunkline.sizeCV_c1,
                            quantity_c1: this.ObjectForTrunkline.quantity_c1,
                            Value_c1: this.ObjectForTrunkline.Value_c1,
                            wa_c1: this.ObjectForTrunkline.wa_c1,
                            eb_c1: this.ObjectForTrunkline.eb_c1,
                            cc_c2: this.ObjectForTrunkline.cc_c2,
                            ic_c2: this.ObjectForTrunkline.ic_c2,
                            size_cvt1: this.ObjectForTrunkline.size_cvt1,
                            total_c1: this.ObjectForTrunkline.total_c1,
                            lpi_c1: this.ObjectForTrunkline.lpi_c1,
                            fl_c1: this.ObjectForTrunkline.fl_c1,
                            eb_c1r1: this.ObjectForTrunkline.eb_c1r1,
                            ic_c2r2: this.ObjectForTrunkline.ic_c2r2,
                            size_c4r2: this.ObjectForTrunkline.size_c4r2,
                            total_5r2: this.ObjectForTrunkline.total_5r2,
                            lpi_6r2: this.ObjectForTrunkline.lpi_6r2,
                            fl_c7r2: this.ObjectForTrunkline.fl_c7r2,
                            ic_c2r3: this.ObjectForTrunkline.ic_c2r3,
                            size_c4r3: this.ObjectForTrunkline.size_c4r3,
                            total_5r3: this.ObjectForTrunkline.total_5r3,
                            lpi_6r3: this.ObjectForTrunkline.lpi_6r3,
                            fl_c7r3: this.ObjectForTrunkline.fl_c7r3,
                            ic_c2r4: this.ObjectForTrunkline.ic_c2r4,
                            sizeCV_c4r4: this.ObjectForTrunkline.sizeCV_c4r4,
                            total_5r4: this.ObjectForTrunkline.total_5r4,
                            lpi_6r4: this.ObjectForTrunkline.lpi_6r4,
                            fl_c7r4: this.ObjectForTrunkline.fl_c7r4,
                            ic_c2r5: this.ObjectForTrunkline.ic_c2r5,
                            size_c4r5: this.ObjectForTrunkline.size_c4r5,
                            total_5r5: this.ObjectForTrunkline.total_5r5,
                            lpi_6r5: this.ObjectForTrunkline.lpi_6r5,
                            fl_c7r5: this.ObjectForTrunkline.fl_c7r5,
                            ic_c2r6: this.ObjectForTrunkline.ic_c2r6,
                            size_c4r6: this.ObjectForTrunkline.size_c4r6,
                            total_5r6: this.ObjectForTrunkline.total_5r6,
                            lpi_6r6: this.ObjectForTrunkline.lpi_6r6,
                            fl_c7r6: this.ObjectForTrunkline.fl_c7r6,
                            size_c4r7: this.ObjectForTrunkline.size_c4r7,
                            total_5r7: this.ObjectForTrunkline.total_5r7,
                            lpi_6r7: this.ObjectForTrunkline.lpi_6r7,
                            fl_c7r7: this.ObjectForTrunkline.fl_c7r7,
                                ic_cb1   : this.ObjectForTrunkline.ic_cb1,
                                ic_c1r2   : this.ObjectForTrunkline.ic_c1r2,
                                ic_c1r3   : this.ObjectForTrunkline.ic_c1r3,
                                ic_c1r4   : this.ObjectForTrunkline.ic_c1r4,
                                ic_c1r5   : this.ObjectForTrunkline.ic_c1r5,
                                size_c3r6   : this.ObjectForTrunkline.size_c3r6,
                                ic_c1r6   : this.ObjectForTrunkline.ic_c1r6,
                                size_cb1   : this.ObjectForTrunkline.size_cb1,
                                size_c3r2   : this.ObjectForTrunkline.size_c3r2,
                                size_c3r3   : this.ObjectForTrunkline.size_c3r3,
                                size_c3r4   : this.ObjectForTrunkline.size_c3r4,
                                size_c3r5   : this.ObjectForTrunkline.size_c3r5,
                                size_c3r7   : this.ObjectForTrunkline.size_c3r7,
                            id: this.ObjectForTrunkline.id,
                            newCv55 : this.ObjectForTrunkline.newCv55,
                            total_cv55_3 : this.ObjectForTrunkline.total_cv55_3,
                            lpi_cv55_4 : this.ObjectForTrunkline.lpi_cv55_4,
                            fl_cv55_5:this.ObjectForTrunkline.fl_cv55_5,
                            sizeCV_c2 : this.ObjectForTrunkline.sizeCV_c2,
                            quantity_c2:this.ObjectForTrunkline.quantity_c2
                        }
            let tableData =   { sentTOkansenDate : dateTime, 
                                code_number:latestRecord.code_number,
                                firstReq_with_changes : '',
                                firstReq_without_changes : dateTime,
                                overDue : 1}

            let tableData2 =   { sentTOkansenDate : dateTime, 
                                firstReq_without_changes : dateTime,
                                code_number:latestRecord.code_number,
                                firstReq_with_changes : '',
                                
                                overDue : 0}   
            
                                
            const NoChangesNisetaiData =  axios({
                    method:'post',
                    url:`${this.$BaseUrl}/api/updateNisetaiSubconOrderWithoutChanges`,
                    data:nisetaiNoChangesDataOrder,
                    headers: {
                        'x-api-key' : this.awsHeaders
                    }
                });
            
            
            //TODO TODAY
            if(dateToday > dueDate){
                Swal.fire({
                icon:'warning',
                title:  '注：',
                html:'上棟日までの納品に間に合う期限を過ぎていますが、依頼をしますか。. ',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                showCancelButton: true,
                confirmButtonText: '確認する',
                cancelButtonText : 'キャンセル',
                allowOutsideClick: () => {
                const popup = this.$swal.getPopup()
                popup.classList.remove('swal2-show')
                return false
                }
            }).then((result) => {
                if (result.isConfirmed) {       
                        if(this.ObjectForTrunkline.nisetai > 1){
                            const updateDuedateValue = axios({
                                            method:'post',
                                            url:`${this.$BaseUrl}/api/updateOverDueRequestFromSubcon`, 
                                            data:tableData,
                                            headers: {
                                                'x-api-key' : this.awsHeaders
                                            }
                                        })
                                                
                            const sentOrderWithoutChanges = axios({
                                                        method:'post',
                                                        url:`${this.$BaseUrl}/api/updateSubconOrderWithoutChanges`,
                                                        data:objectForOrder,
                                                        headers: {
                                                            'x-api-key' : this.awsHeaders
                                                        }
                                                    }) 
                            const updateKansenSystemTable = axios({
                                                        method:'post',
                                                        url:`${this.$BaseUrl}/api/sendBackRequest`,
                                                        data:tableData2,
                                                        headers: {
                                                            'x-api-key' : this.awsHeaders
                                                        }
                                                    })                           
                            Promise.all([updateDuedateValue, sentOrderWithoutChanges,updateKansenSystemTable,NoChangesNisetaiData]).then(()=> {
                                message.success({
                                        bottom: '100px',
                                        duration: 2,
                                        maxCount: 3,
                                        text:'Order Sent Without Changes!',
                                        });
                                
                                this.secondRequestFormDialog = false;
                                bus.$emit('LoadHomePage')
                                setTimeout(function() {
                                location.reload();
                                    }, 1000);
                                
                                })
                        }else{
                            const updateDuedateValue = axios({
                                            method:'post',
                                            url:`${this.$BaseUrl}/api/updateOverDueRequestFromSubcon`, 
                                            data:tableData,
                                            headers: {
                                                'x-api-key' : this.awsHeaders
                                            }
                                        })

                            const sentOrderWithoutChanges = axios({
                                                        method:'post',
                                                        url:`${this.$BaseUrl}/api/updateSubconOrderWithoutChanges`,
                                                        data:objectForOrder,
                                                        headers: {
                                                            'x-api-key' : this.awsHeaders
                                                        }
                                                    }) 
                            const updateKansenSystemTable = axios({
                                                        method:'post',
                                                        url:`${this.$BaseUrl}/api/sendBackRequest`,
                                                        data:tableData2,
                                                        headers: {
                                                            'x-api-key' : this.awsHeaders
                                                        }
                                                    })                           
                            Promise.all([updateDuedateValue, sentOrderWithoutChanges,updateKansenSystemTable]).then(()=> {
                                message.success({
                                        bottom: '100px',
                                        duration: 2,
                                        maxCount: 3,
                                        text:'Order Sent Without Changes!',
                                        });
                                
                                this.secondRequestFormDialog = false;
                                bus.$emit('LoadHomePage')
                                setTimeout(function() {
                                location.reload();
                                    }, 1000);
                                
                                })
                    }
                            
                            
                    }else{
                        return false
                    }
                })
            }else{
                Swal.fire({
                icon:'question',
                title: 'Are your sure?',
                html:'Send request without changes? ',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText : 'Cancel',
                allowOutsideClick: () => {
                const popup = this.$swal.getPopup()
                popup.classList.remove('swal2-show')
                return false
                }
            }).then((result) => {
                if (result.isConfirmed) {       

                    const updateDuedateValue = axios({
                                            method:'post',
                                            url:`${this.$BaseUrl}/api/updateOverDueRequestFromSubcon`, 
                                            data:tableData2,
                                            headers: {
                                                'x-api-key' : this.awsHeaders
                                            }
                                        })

                    const sentOrderWithoutChanges = axios({
                                                method:'post',
                                                url:`${this.$BaseUrl}/api/updateSubconOrderWithoutChanges`,
                                                data:objectForOrder,
                                                headers: {
                                                    'x-api-key' : this.awsHeaders
                                                }
                                            })
                    const updateKansenSystemTable = axios({
                                                method:'post',
                                                url:`${this.$BaseUrl}/api/sendBackRequest`,
                                                data:tableData2,
                                                headers: {
                                                    'x-api-key' : this.awsHeaders
                                                }
                                            })                         
                    Promise.all([updateDuedateValue, sentOrderWithoutChanges,updateKansenSystemTable ]).then(()=> {
                        message.success({
                                bottom: '100px',
                                duration: 2,
                                maxCount: 3,
                                text:'Order Sent Without Changes!',
                                });
                        
                        this.secondRequestFormDialog = false;
                        bus.$emit('LoadHomePage')
                        setTimeout(function() {
                        location.reload();
                            }, 1000);
                        
                        })
                            
                    }else{
                        return false
                    }
                })
            }
    
           

    },///end of orderwithout changes



    saveOrderChanges(){
 
        if(this.checkBox13){
                    if(this.checkBox11 && this.checkBox12){
                        this.ObjectForTrunkline.size_c3r6 = 1
                        this.ObjectForTrunkline.ic_c1r6 = 1
                    }else if(this.checkBox10 && this.checkBox9){
                        this.ObjectForTrunkline.ic_c1r5 = 1
                        this.ObjectForTrunkline.size_c3r5 = 1
                    }else if(this.checkBox8 && this.checkBox7){
                        this.ObjectForTrunkline.ic_c1r4 = 1
                        this.ObjectForTrunkline.size_c3r4 = 1
                    }else if(this.checkBox6 && this.checkBox5){
                        this.ObjectForTrunkline.ic_c1r3 = 1
                        this.ObjectForTrunkline.size_c3r3 = 1
                    }else if(this.checkBox4 && this.checkBox3){
                        this.ObjectForTrunkline.ic_c1r2 = 1
                        this.ObjectForTrunkline.size_c3r2 = 1
                    }else if(this.checkBox2 && this.checkBox1){
                        this.ObjectForTrunkline.ic_cb1 = 1
                        this.ObjectForTrunkline.size_cb1 = 1
                    } 
            this.ObjectForTrunkline.size_c3r7 = 1
        }else if(this.checkBox11 && this.checkBox12){
                    if(this.checkBox10 && this.checkBox9){
                        this.ObjectForTrunkline.ic_c1r5 = 1
                        this.ObjectForTrunkline.size_c3r5 = 1
                    }else if(this.checkBox8 && this.checkBox7){
                        this.ObjectForTrunkline.ic_c1r4 = 1
                        this.ObjectForTrunkline.size_c3r4 = 1
                    }else if(this.checkBox6 && this.checkBox5){
                        this.ObjectForTrunkline.ic_c1r3 = 1
                        this.ObjectForTrunkline.size_c3r3 = 1
                    }else if(this.checkBox4 && this.checkBox3){
                        this.ObjectForTrunkline.ic_c1r2 = 1
                        this.ObjectForTrunkline.size_c3r2 = 1
                    }else if(this.checkBox2 && this.checkBox1){
                        this.ObjectForTrunkline.ic_cb1 = 1
                        this.ObjectForTrunkline.size_cb1 = 1
                    }else if(this.checkBox13){
                        this.ObjectForTrunkline.size_c3r7 = 1   
                    } 
            this.ObjectForTrunkline.size_c3r6 = 1
            this.ObjectForTrunkline.ic_c1r6 = 1
        }else if(this.checkBox10 && this.checkBox9){
                    if(this.checkBox11 && this.checkBox12){
                        this.ObjectForTrunkline.size_c3r6 = 1
                        this.ObjectForTrunkline.ic_c1r6 = 1
                    }else if(this.checkBox8 && this.checkBox7){
                        this.ObjectForTrunkline.ic_c1r4 = 1
                        this.ObjectForTrunkline.size_c3r4 = 1
                    }else if(this.checkBox6 && this.checkBox5){
                        this.ObjectForTrunkline.ic_c1r3 = 1
                        this.ObjectForTrunkline.size_c3r3 = 1
                    }else if(this.checkBox4 && this.checkBox3){
                        this.ObjectForTrunkline.ic_c1r2 = 1
                        this.ObjectForTrunkline.size_c3r2 = 1
                    }else if(this.checkBox2 && this.checkBox1){
                        this.ObjectForTrunkline.ic_cb1 = 1
                        this.ObjectForTrunkline.size_cb1 = 1
                    }else if(this.checkBox13){
                        this.ObjectForTrunkline.size_c3r7 = 1   
                    } 
            this.ObjectForTrunkline.ic_c1r5 = 1
            this.ObjectForTrunkline.size_c3r5 = 1
        }else if(this.checkBox8 && this.checkBox7){
                    if(this.checkBox11 && this.checkBox12){
                        this.ObjectForTrunkline.size_c3r6 = 1
                        this.ObjectForTrunkline.ic_c1r6 = 1
                    }else if(this.checkBox10 && this.checkBox9){
                        this.ObjectForTrunkline.ic_c1r5 = 1
                        this.ObjectForTrunkline.size_c3r5 = 1
                    }else if(this.checkBox6 && this.checkBox5){
                        this.ObjectForTrunkline.ic_c1r3 = 1
                        this.ObjectForTrunkline.size_c3r3 = 1
                    }else if(this.checkBox4 && this.checkBox3){
                        this.ObjectForTrunkline.ic_c1r2 = 1
                        this.ObjectForTrunkline.size_c3r2 = 1
                    }else if(this.checkBox2 && this.checkBox1){
                        this.ObjectForTrunkline.ic_cb1 = 1
                        this.ObjectForTrunkline.size_cb1 = 1
                    }else if(this.checkBox13){
                        this.ObjectForTrunkline.size_c3r7 = 1   
                    }else if(this.checkBox13){
                        this.ObjectForTrunkline.size_c3r7 = 1   
                    }
            this.ObjectForTrunkline.ic_c1r4 = 1
            this.ObjectForTrunkline.size_c3r4 = 1
        }else if(this.checkBox6 && this.checkBox5){
                    if(this.checkBox11 && this.checkBox12){
                        this.ObjectForTrunkline.size_c3r6 = 1
                        this.ObjectForTrunkline.ic_c1r6 = 1
                    }else if(this.checkBox10 && this.checkBox9){
                        this.ObjectForTrunkline.ic_c1r5 = 1
                        this.ObjectForTrunkline.size_c3r5 = 1
                    }else if(this.checkBox8 && this.checkBox7){
                        this.ObjectForTrunkline.ic_c1r4 = 1
                        this.ObjectForTrunkline.size_c3r4 = 1
                    }else if(this.checkBox4 && this.checkBox3){
                        this.ObjectForTrunkline.ic_c1r2 = 1
                        this.ObjectForTrunkline.size_c3r2 = 1
                    }else if(this.checkBox2 && this.checkBox1){
                        this.ObjectForTrunkline.ic_cb1 = 1
                        this.ObjectForTrunkline.size_cb1 = 1
                    }else if(this.checkBox13){
                        this.ObjectForTrunkline.size_c3r7 = 1   
                    } 
            this.ObjectForTrunkline.ic_c1r3 = 1
            this.ObjectForTrunkline.size_c3r3 = 1
        }else if(this.checkBox4 && this.checkBox3){
                    if(this.checkBox11 && this.checkBox12){
                        this.ObjectForTrunkline.size_c3r6 = 1
                        this.ObjectForTrunkline.ic_c1r6 = 1
                    }else if(this.checkBox10 && this.checkBox9){
                        this.ObjectForTrunkline.ic_c1r5 = 1
                        this.ObjectForTrunkline.size_c3r5 = 1
                    }else if(this.checkBox8 && this.checkBox7){
                        this.ObjectForTrunkline.ic_c1r4 = 1
                        this.ObjectForTrunkline.size_c3r4 = 1
                    }else if(this.checkBox6 && this.checkBox5){
                        this.ObjectForTrunkline.ic_c1r3 = 1
                        this.ObjectForTrunkline.size_c3r3 = 1
                    }else if(this.checkBox2 && this.checkBox1){
                        this.ObjectForTrunkline.ic_cb1 = 1
                        this.ObjectForTrunkline.size_cb1 = 1
                    }else if(this.checkBox13){
                        this.ObjectForTrunkline.size_c3r7 = 1   
                    }
            this.ObjectForTrunkline.ic_c1r2 = 1
            this.ObjectForTrunkline.size_c3r2 = 1
        }else if(this.checkBox2 && this.checkBox1){
                    if(this.checkBox11 && this.checkBox12){
                        this.ObjectForTrunkline.size_c3r6 = 1
                        this.ObjectForTrunkline.ic_c1r6 = 1
                    }else if(this.checkBox10 && this.checkBox9){
                        this.ObjectForTrunkline.ic_c1r5 = 1
                        this.ObjectForTrunkline.size_c3r5 = 1
                    }else if(this.checkBox8 && this.checkBox7){
                        this.ObjectForTrunkline.ic_c1r4 = 1
                        this.ObjectForTrunkline.size_c3r4 = 1
                    }else if(this.checkBox6 && this.checkBox5){
                        this.ObjectForTrunkline.ic_c1r3 = 1
                        this.ObjectForTrunkline.size_c3r3 = 1
                    }else if(this.checkBox4 && this.checkBox3){
                        this.ObjectForTrunkline.ic_c1r2 = 1
                        this.ObjectForTrunkline.size_c3r2 = 1
                    }else if(this.checkBox13){
                        this.ObjectForTrunkline.size_c3r7 = 1   
                    } 
            this.ObjectForTrunkline.ic_cb1 = 1
            this.ObjectForTrunkline.size_cb1 = 1
        }                                


    
        let latestIndex = this.myObject.length - 1;
        let latestRecord = this.myObject[latestIndex];

            const today = new Date();
            const year = today.getFullYear();
            const month = (today.getMonth() + 1).toString().padStart(2, '0');
            const day = today.getDate().toString().padStart(2, '0');
            const date = `${year}-${month}-${day}`;

            const hours = today.getHours().toString().padStart(2, '0');
            const minutes = today.getMinutes().toString().padStart(2, '0');
            const seconds = today.getSeconds().toString().padStart(2, '0');
            const formattedTime = `${hours}:${minutes}:${seconds}`;

            const dateTime = `${date} | ${formattedTime}`;

            var dateToday = new Date(date);
            var dueDate = new Date(this.ObjectForTrunkline.lessjoutouDate);
            

        let objectForOrder = {
                            code_number: this.ObjectForTrunkline.code_number,
                            first_request_date : date,
                            cc_c1: this.ObjectForTrunkline.cc_c1,
                            kansen_blankc1: this.ObjectForTrunkline.kansen_blankc1,
                            sizeCV_c1: this.ObjectForTrunkline.sizeCV_c1,
                            quantity_c1: this.ObjectForTrunkline.quantity_c1,
                            Value_c1: this.ObjectForTrunkline.Value_c1,
                            wa_c1: this.ObjectForTrunkline.wa_c1,
                            eb_c1: this.ObjectForTrunkline.eb_c1,
                            cc_c2: this.ObjectForTrunkline.cc_c2,
                            ic_c2: this.ObjectForTrunkline.ic_c2,
                            size_cvt1: this.ObjectForTrunkline.size_cvt1,
                            total_c1: this.ObjectForTrunkline.total_c1,
                            lpi_c1: this.ObjectForTrunkline.lpi_c1,
                            fl_c1: this.ObjectForTrunkline.fl_c1,
                            eb_c1r1: this.ObjectForTrunkline.eb_c1r1,
                            ic_c2r2: this.ObjectForTrunkline.ic_c2r2,
                            size_c4r2: this.ObjectForTrunkline.size_c4r2,
                            total_5r2: this.ObjectForTrunkline.total_5r2,
                            lpi_6r2: this.ObjectForTrunkline.lpi_6r2,
                            fl_c7r2: this.ObjectForTrunkline.fl_c7r2,
                            ic_c2r3: this.ObjectForTrunkline.ic_c2r3,
                            size_c4r3: this.ObjectForTrunkline.size_c4r3,
                            total_5r3: this.ObjectForTrunkline.total_5r3,
                            lpi_6r3: this.ObjectForTrunkline.lpi_6r3,
                            fl_c7r3: this.ObjectForTrunkline.fl_c7r3,
                            ic_c2r4: this.ObjectForTrunkline.ic_c2r4,
                            sizeCV_c4r4: this.ObjectForTrunkline.sizeCV_c4r4,
                            total_5r4: this.ObjectForTrunkline.total_5r4,
                            lpi_6r4: this.ObjectForTrunkline.lpi_6r4,
                            fl_c7r4: this.ObjectForTrunkline.fl_c7r4,
                            ic_c2r5: this.ObjectForTrunkline.ic_c2r5,
                            size_c4r5: this.ObjectForTrunkline.size_c4r5,
                            total_5r5: this.ObjectForTrunkline.total_5r5,
                            lpi_6r5: this.ObjectForTrunkline.lpi_6r5,
                            fl_c7r5: this.ObjectForTrunkline.fl_c7r5,
                            ic_c2r6: this.ObjectForTrunkline.ic_c2r6,
                            size_c4r6: this.ObjectForTrunkline.size_c4r6,
                            total_5r6: this.ObjectForTrunkline.total_5r6,
                            lpi_6r6: this.ObjectForTrunkline.lpi_6r6,
                            fl_c7r6: this.ObjectForTrunkline.fl_c7r6,
                            size_c4r7: this.ObjectForTrunkline.size_c4r7,
                            total_5r7: this.ObjectForTrunkline.total_5r7,
                            lpi_6r7: this.ObjectForTrunkline.lpi_6r7,
                            fl_c7r7: this.ObjectForTrunkline.fl_c7r7,
                                ic_cb1   : this.ObjectForTrunkline.ic_cb1,
                                ic_c1r2   : this.ObjectForTrunkline.ic_c1r2,
                                ic_c1r3   : this.ObjectForTrunkline.ic_c1r3,
                                ic_c1r4   : this.ObjectForTrunkline.ic_c1r4,
                                ic_c1r5   : this.ObjectForTrunkline.ic_c1r5,
                                size_c3r6   : this.ObjectForTrunkline.size_c3r6,
                                ic_c1r6   : this.ObjectForTrunkline.ic_c1r6,
                                size_cb1   : this.ObjectForTrunkline.size_cb1,
                                size_c3r2   : this.ObjectForTrunkline.size_c3r2,
                                size_c3r3   : this.ObjectForTrunkline.size_c3r3,
                                size_c3r4   : this.ObjectForTrunkline.size_c3r4,
                                size_c3r5   : this.ObjectForTrunkline.size_c3r5,
                                size_c3r7   : this.ObjectForTrunkline.size_c3r7,
                            id: this.ObjectForTrunkline.id,
                            newCv55 : this.ObjectForTrunkline.newCv55,
                            total_cv55_3 : this.ObjectForTrunkline.total_cv55_3,
                            lpi_cv55_4 : this.ObjectForTrunkline.lpi_cv55_4,
                            fl_cv55_5:this.ObjectForTrunkline.fl_cv55_5,
                            sizeCV_c2 : this.ObjectForTrunkline.sizeCV_c2,
                            quantity_c2:this.ObjectForTrunkline.quantity_c2
                        }
        
        let nisetaiDataOrder = {
            code_number: this.nisetaiData.code_number,
            first_request_date : date,
            cc_c1: this.nisetaiData.cc_c1,
            kansen_blankc1: this.nisetaiData.kansen_blankc1,
            sizeCV_c1: this.nisetaiData.sizeCV_c1,
            quantity_c1: this.nisetaiData.quantity_c1,
            Value_c1: this.nisetaiData.Value_c1,
            wa_c1: this.nisetaiData.wa_c1,
            eb_c1: this.nisetaiData.eb_c1,
            cc_c2: this.nisetaiData.cc_c2,
            ic_c2: this.nisetaiData.ic_c2,
            size_cvt1: this.nisetaiData.size_cvt1,
            total_c1: this.nisetaiData.total_c1,
            lpi_c1: this.nisetaiData.lpi_c1,
            fl_c1: this.nisetaiData.fl_c1,
            eb_c1r1: this.nisetaiData.eb_c1r1,
            ic_c2r2: this.nisetaiData.ic_c2r2,
            size_c4r2: this.nisetaiData.size_c4r2,
            total_5r2: this.nisetaiData.total_5r2,
            lpi_6r2: this.nisetaiData.lpi_6r2,
            fl_c7r2: this.nisetaiData.fl_c7r2,
            ic_c2r3: this.nisetaiData.ic_c2r3,
            size_c4r3: this.nisetaiData.size_c4r3,
            total_5r3: this.nisetaiData.total_5r3,
            lpi_6r3: this.nisetaiData.lpi_6r3,
            fl_c7r3: this.nisetaiData.fl_c7r3,
            ic_c2r4: this.nisetaiData.ic_c2r4,
            sizeCV_c4r4: this.nisetaiData.sizeCV_c4r4,
            total_5r4: this.nisetaiData.total_5r4,
            lpi_6r4: this.nisetaiData.lpi_6r4,
            fl_c7r4: this.nisetaiData.fl_c7r4,
            ic_c2r5: this.nisetaiData.ic_c2r5,
            size_c4r5: this.nisetaiData.size_c4r5,
            total_5r5: this.nisetaiData.total_5r5,
            lpi_6r5: this.nisetaiData.lpi_6r5,
            fl_c7r5: this.nisetaiData.fl_c7r5,
            ic_c2r6: this.nisetaiData.ic_c2r6,
            size_c4r6: this.nisetaiData.size_c4r6,
            total_5r6: this.nisetaiData.total_5r6,
            lpi_6r6: this.nisetaiData.lpi_6r6,
            fl_c7r6: this.nisetaiData.fl_c7r6,
            size_c4r7: this.nisetaiData.size_c4r7,
            total_5r7: this.nisetaiData.total_5r7,
            lpi_6r7: this.nisetaiData.lpi_6r7,
            fl_c7r7: this.nisetaiData.fl_c7r7,
                ic_cb1   : this.nisetaiData.ic_cb1,
                ic_c1r2   : this.nisetaiData.ic_c1r2,
                ic_c1r3   : this.nisetaiData.ic_c1r3,
                ic_c1r4   : this.nisetaiData.ic_c1r4,
                ic_c1r5   : this.nisetaiData.ic_c1r5,
                size_c3r6   : this.nisetaiData.size_c3r6,
                ic_c1r6   : this.nisetaiData.ic_c1r6,
                size_cb1   : this.nisetaiData.size_cb1,
                size_c3r2   : this.nisetaiData.size_c3r2,
                size_c3r3   : this.nisetaiData.size_c3r3,
                size_c3r4   : this.nisetaiData.size_c3r4,
                size_c3r5   : this.nisetaiData.size_c3r5,
                size_c3r7   : this.nisetaiData.size_c3r7,
            id: this.nisetaiData.id,
            newCv55 : this.nisetaiData.newCv55,
            total_cv55_3 : this.nisetaiData.total_cv55_3,
            lpi_cv55_4 : this.nisetaiData.lpi_cv55_4,
            fl_cv55_5:this.nisetaiData.fl_cv55_5,
            sizeCV_c2 : this.nisetaiData.sizeCV_c2,
            quantity_c2:this.nisetaiData.quantity_c2
            }

            let overDueData =   { sentTOkansenDate : dateTime, 
                    code_number:latestRecord.code_number,
                    firstReq_with_changes : dateTime,
                    firstReq_without_changes:'',
                    overDue : 1}

            let tableData2 =   { sentTOkansenDate : dateTime, 
                    code_number:latestRecord.code_number,
                    firstReq_with_changes : dateTime,
                    firstReq_without_changes:'',
                    overDue : 0} 

            const changesNisetaiData =  axios({
                                        method:'post',
                                        url:`${this.$BaseUrl}/api/updateNisetaiSubconOrder`,
                                        data:nisetaiDataOrder,
                                        headers: {
                                            'x-api-key' : this.awsHeaders
                                        }
                                    });
        
                        if(dateToday > dueDate){
                            if(this.ObjectForTrunkline.nisetai > 1){
                                Swal.fire({
                                icon:'warning',
                                title: '注：',
                                html:'上棟日までの納品に間に合う期限を過ぎていますが、依頼をしますか。.',
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                showCancelButton: true,
                                confirmButtonText: '確認する',
                                cancelButtonText : 'キャンセル',
                                allowOutsideClick: () => {
                                const popup = this.$swal.getPopup()
                                popup.classList.remove('swal2-show')
                                return false
                                }
                                }).then((result) => {
                                    if (result.isConfirmed) {

                                            const updateSubconMainOverDue =   axios({
                                                            method:'post',
                                                            url:`${this.$BaseUrl}/api/updateOverDueRequestFromSubcon`, 
                                                            data:overDueData,
                                                            headers: {
                                                                'x-api-key' : this.awsHeaders
                                                            }
                                                        });
                                            const sentOrderChangesOverDue = axios({
                                                    method:'post',
                                                    url:`${this.$BaseUrl}/api/updateSubconOrder`,
                                                    data:objectForOrder,
                                                    headers: {
                                                        'x-api-key' : this.awsHeaders
                                                    }
                                                }); 
                                            
                                            const updateKansenSystemTableOverDue = axios({
                                                                method:'post',
                                                                url:`${this.$BaseUrl}/api/sendBackRequest`,
                                                                data:tableData2,
                                                                headers: {
                                                                    'x-api-key' : this.awsHeaders
                                                                }
                                                            });
                                            Promise.all([updateSubconMainOverDue,sentOrderChangesOverDue,updateKansenSystemTableOverDue,changesNisetaiData]) 
                                                .then(()=>{
                                                    this.$message.loading({
                                                                    content: 'Loading...',
                                                                    key,
                                                                    style: {
                                                                        left: '50%',
                                                                        top: '50%',
                                                                        transform: 'translate(-50%, -50%)',
                                                                    },
                                                                    });

                                                                    setTimeout(() => {
                                                                    this.$message.success({
                                                                        content: 'Changes Confirmed',
                                                                        key,
                                                                        duration: 3,
                                                                        style: {
                                                                        left: '100px',
                                                                        top: '10%',
                                                                        transform: 'translateY(-50%)',
                                                                        },
                                                                    });
                                                                    }, 1000);
                                                            this.isChangeOrderConfirmed = false        
                                                            this.secondRequestFormDialog = false;
                                                            setTimeout(function() {
                                                                location.reload();
                                                            }, 1000);
                                                })         
                                            
                                    }else{
                                        return false
                                    }
                                })
                            }else{
                                Swal.fire({
                            icon:'warning',
                            title: '注：',
                            html:'上棟日までの納品に間に合う期限を過ぎていますが、依頼をしますか。.',
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            showCancelButton: true,
                            confirmButtonText: '確認する',
                            cancelButtonText : 'キャンセル',
                            allowOutsideClick: () => {
                            const popup = this.$swal.getPopup()
                            popup.classList.remove('swal2-show')
                            return false
                            }
                            }).then((result) => {
                                if (result.isConfirmed) {

                                        const updateSubconMainOverDue =   axios({
                                                        method:'post',
                                                        url:`${this.$BaseUrl}/api/updateOverDueRequestFromSubcon`, 
                                                        data:overDueData,
                                                        headers: {
                                                            'x-api-key' : this.awsHeaders
                                                        }
                                                    });
                                        const sentOrderChangesOverDue = axios({
                                                method:'post',
                                                url:`${this.$BaseUrl}/api/updateSubconOrder`,
                                                data:objectForOrder,
                                                headers: {
                                                    'x-api-key' : this.awsHeaders
                                                }
                                            }); 
                                        
                                        const updateKansenSystemTableOverDue = axios({
                                                            method:'post',
                                                            url:`${this.$BaseUrl}/api/sendBackRequest`,
                                                            data:tableData2,
                                                            headers: {
                                                                'x-api-key' : this.awsHeaders
                                                            }
                                                        });
                                        Promise.all([updateSubconMainOverDue,sentOrderChangesOverDue,updateKansenSystemTableOverDue]) 
                                            .then(()=>{
                                                this.$message.loading({
                                                                content: 'Loading...',
                                                                key,
                                                                style: {
                                                                    left: '50%',
                                                                    top: '50%',
                                                                    transform: 'translate(-50%, -50%)',
                                                                },
                                                                });

                                                                setTimeout(() => {
                                                                this.$message.success({
                                                                    content: 'Changes Confirmed',
                                                                    key,
                                                                    duration: 3,
                                                                    style: {
                                                                    left: '100px',
                                                                    top: '10%',
                                                                    transform: 'translateY(-50%)',
                                                                    },
                                                                });
                                                                }, 1000);
                                                        this.isChangeOrderConfirmed = false        
                                                        this.secondRequestFormDialog = false;
                                                        setTimeout(function() {
                                                        location.reload();
                                                        }, 1000);
                                            })         
                                        
                                }else{
                                    return false
                                }
                            })  
                            }
                            
                        }else{
                            Swal.fire({
                            icon:'question',
                            html: 'Are you sure about the changes <br>you want to make in your order?',
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            showCancelButton: true,
                            confirmButtonText: 'Confirm',
                            cancelButtonText : 'Cancel',
                            allowOutsideClick: () => {
                            const popup = this.$swal.getPopup()
                            popup.classeList.remove('swal2-show')
                            return false
                            }
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    if(this.ObjectForTrunkline.nisetai > 1){
                                        const updateSubconMainTable =   axios({
                                            method:'post',
                                            url:`${this.$BaseUrl}/api/updateOverDueRequestFromSubcon`, 
                                            data:tableData2,
                                            headers: {
                                                'x-api-key' : this.awsHeaders
                                            }
                                        });
                                        const sentOrderChanges = axios({
                                                method:'post',
                                                url:`${this.$BaseUrl}/api/updateSubconOrder`,
                                                data:objectForOrder,
                                                headers: {
                                                    'x-api-key' : this.awsHeaders
                                                }
                                            }); 
                                        
                                        const updateKansenSystemTable = axios({
                                                        method:'post',
                                                        url:`${this.$BaseUrl}/api/sendBackRequest`,
                                                        data:tableData2,
                                                        headers: {
                                                            'x-api-key' : this.awsHeaders
                                                        }
                                                    });
                                                    
                                    Promise.all([updateSubconMainTable,sentOrderChanges,updateKansenSystemTable,changesNisetaiData])
                                        .then(() => {
                                            this.$message.loading({
                                                    content: 'Loading...',
                                                    key,
                                                    style: {
                                                        left: '50%',
                                                        top: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                    },
                                                    });

                                                    setTimeout(() => {
                                                    this.$message.success({
                                                        content: 'Changes Confirmed',
                                                        key,
                                                        duration: 3,
                                                        style: {
                                                        left: '100px',
                                                        top: '10%',
                                                        transform: 'translateY(-50%)',
                                                        },
                                                    });
                                                    }, 1000);
                                            this.isChangeOrderConfirmed = false        
                                            this.secondRequestFormDialog = false;
                                            setTimeout(function() {
                                            location.reload();
                                            }, 1000);
                                        })
                                        .catch((error) => {
                                            console.error('Error:', error);
                                        });
                                    }else{
                                        const updateSubconMainTable =   axios({
                                            method:'post',
                                            url:`${this.$BaseUrl}/api/updateOverDueRequestFromSubcon`, 
                                            data:tableData2,
                                            headers: {
                                                'x-api-key' : this.awsHeaders
                                            }
                                        });
                                        const sentOrderChanges = axios({
                                                method:'post',
                                                url:`${this.$BaseUrl}/api/updateSubconOrder`,
                                                data:objectForOrder,
                                                headers: {
                                                    'x-api-key' : this.awsHeaders
                                                }
                                            }); 
                                        
                                        const updateKansenSystemTable = axios({
                                                        method:'post',
                                                        url:`${this.$BaseUrl}/api/sendBackRequest`,
                                                        data:tableData2,
                                                        headers: {
                                                            'x-api-key' : this.awsHeaders
                                                        }
                                                    });
                                                    
                                    Promise.all([updateSubconMainTable,sentOrderChanges,updateKansenSystemTable])
                                        .then(() => {
                                            this.$message.loading({
                                                    content: 'Loading...',
                                                    key,
                                                    style: {
                                                        left: '50%',
                                                        top: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                    },
                                                    });

                                                    setTimeout(() => {
                                                    this.$message.success({
                                                        content: 'Changes Confirmed',
                                                        key,
                                                        duration: 3,
                                                        style: {
                                                        left: '100px',
                                                        top: '10%',
                                                        transform: 'translateY(-50%)',
                                                        },
                                                    });
                                                    }, 1000);
                                            this.isChangeOrderConfirmed = false        
                                            this.secondRequestFormDialog = false;
                                            setTimeout(function() {
                                            location.reload();
                                            }, 1000);
                                        })
                                        .catch((error) => {
                                            console.error('Error:', error);
                                        });
                                    }                                        
                            }else{
                                return false
                            }
                        })     
                        }
            
        
    },

    
  


 

    }, //end of method

        
   

    mounted(){
        this.UpdateCc1()
      
        

        // this.loadDataForTrunkline()
     

        bus.$on('secondRequestFormDialog' , () =>{
            this.secondRequestFormDialog = true;
            })

           
    },

    created(){  
        
    },

   

    computed:{
        

        
    },

    watch: {
   

        "ObjectForTrunkline.fl_c7r7": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                this.checkBox7 = true; 
                // this.checkBox8 = true;
                } else {
                this.checkBox7 = false;
                // this.checkBox8 = false;
                }
            },
        },

        "ObjectForTrunkline.fl_c7r6": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                this.checkBox5 = true; 
                this.checkBox6 = true;
                } else {
                this.checkBox5 = false;
                this.checkBox6 = false;
                }
            },
        },

        "ObjectForTrunkline.fl_c7r5": {
            immediate: true, 
            handler(newVal) {
                
                if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                this.checkBox3 = true; 
                this.checkBox4 = true;
                } else {
                this.checkBox3 = false;
                this.checkBox4 = false;
                }
            },
        },

        "ObjectForTrunkline.fl_c7r3": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                this.checkBox1 = true; 
                this.checkBox2 = true;
                } else {
                this.checkBox1 = false;
                this.checkBox2 = false;
                }
            },
        },

        "ObjectForTrunkline.fl_cv55_5": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                    this.checkBox8 = true; 
                } else {
                this.checkBox8 = false;
                }
            },
        },
        

        "nisetaiData.fl_cv55_5": {
            immediate: true, 
            handler(newVal) {
            
                if (newVal && typeof newVal === 'number' && newVal !== 0 || (typeof newVal === 'string' && newVal.trim() !== "")) {
                    this.checkBoxNesitai8 = true; 
                } else {
                this.checkBoxNesitai8 = false;
                }
            },
        },


    
            
        dataFromLocal_Parent(newVal){
            this.dataFromParent = newVal
        
        },

        async forSubconReq(newValue) {
            const headers = { 'x-api-key' : this.awsHeaders };
            let nisetaiData = await axios.get(`${this.$BaseUrl}/api/getNisetaiData/${newValue.code_number}`, { headers });
            if(nisetaiData.status == 200){

                const nisetaiObject = nisetaiData.data.reduce((result, currentObject) => {
 
                    Object.assign(result, currentObject);
                            return result;
                    }, {});
                    this.nisetaiData = nisetaiObject
                    console.log(this.nisetaiData,'LINE 2481');

                    this.myObject.push(newValue);

                    let latestIndex = this.myObject.length - 1;
                    let latestRecord = this.myObject[latestIndex];
                    this.withChangeInOrder = latestRecord.firstReq_with_changes
                    this.noChangesInOrder = latestRecord.firstReq_without_changes
                    // const recordfound = this.dataForTrqunkline.find(a => a.code_number === latestRecord.code_number)
                    const recordfound = this.dataFromParent.find(a => a.code_number === latestRecord.code_number)
                    this.ObjectForTrunkline = recordfound;

            }else{
                this.myObject.push(newValue);

                let latestIndex = this.myObject.length - 1;
                let latestRecord = this.myObject[latestIndex]; 
                this.withChangeInOrder = latestRecord.firstReq_with_changes
                this.noChangesInOrder = latestRecord.firstReq_without_changes
                // const recordfound = this.dataForTrunkline.find(a => a.code_number === latestRecord.code_number)
                const recordfound = this.dataFromParent.find(a => a.code_number === latestRecord.code_number)
                this.ObjectForTrunkline = recordfound;
            }
            // console.log(this.ObjectForTrunkline,'NEWVAL');s
        }, 
        
    
        
    
 
    },
    }
</script>

<style lang="scss" scoped>

.bordered-card {
  border: 2px solid rgb(0, 0, 0);
  margin: 10px 10px 10px 10px;
}
custom-checkbox input[type="checkbox"] {
  /* Set desired width and height for the checkbox */
  width: 50px;
  height: 50px;
}


.fieldclass2 {
    background-color:rgba(104, 104, 104, 0.121);
    font-weight: bold;
    color:rgb(0, 0, 0);
}
.fieldclass {
    background-color:rgba(0, 108, 2, 0.236);
    font-weight: bold;
    color:rgb(0, 0, 0);
}


</style>
